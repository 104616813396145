import { BellOutlined } from "@ant-design/icons"
import { AuthContext, signOutData } from "contexts/AuthContext"
import { Avatar, Badge, Button, Dropdown, List, MenuProps, Popover, Tooltip } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Eraser, Power, User, X } from "lucide-react"
import { getNotificationsViewByUserUuid, putNotificationViewed } from "services/notifications.service"
import { NotificationContext } from "contexts/NotificationContext"


const getTimeDifference = (dateString: Date) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInMs = now.getTime() - date.getTime();

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
        return `${seconds} seconds ago`;
    } else if (minutes < 60) {
        return `${minutes} minutes ago`;
    } else if (hours < 24) {
        return `${hours} hours ago`;
    } else if (days < 30) {
        return `${days} days ago`;
    } else if (months < 12) {
        return `${months} months ago`;
    } else {
        return `${years} years ago`;
    }
};

export const HeaderLayoutBase = () => {

    const { user } = useContext(AuthContext);
    const { notifications, setNotifications } = useContext(NotificationContext);

    const navigate = useNavigate();

    const [notificationView, setNotificationView] = useState<boolean>(false)

    const newNotificationsLength = notifications.filter(item => item.viewed === false).length;

    const getListNotifications = async () => {
        try {
            if (user && user.uuid) {
                const response = await getNotificationsViewByUserUuid(user.uuid)
                setNotifications(response.data)
            }
        } catch (error) {
            console.error("Error fetching notifications data:", error);
        }
    }

    const handleUpdateView = async (notifications: ReadListNotificationDto[]) => {
        try {
            if (user && user.uuid) {
                const uuids = notifications.map(notification => notification.uuid);
                const data: UpdateNotificationViewedDto = {
                    uuids: uuids,
                }
                await putNotificationViewed(data)

                setNotifications((prevNotifications) =>
                    prevNotifications.filter((notification) => !uuids.includes(notification.uuid))
                );
                setNotificationView(true)
            }
        } catch (error) {
            console.error("Error updating notifications data:", error);
        }
    }

    const handleLogout = () => {
        signOutData();
        window.location.href = "/";
    };



    useEffect(() => {
        getListNotifications()

    }, [user]);

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: <a className="flex items-center gap-1" onClick={() => navigate(`/analyst/profile`)}><User size={16} /> Profile</a>,
        },
        {
            key: "2",
            label: (
                <a className="flex items-center gap-1" rel="noopener noreferrer" onClick={handleLogout}>
                    <Power size={16} /> Logout
                </a>
            ),
        },
    ];

    const content = (
        <>
            <div className="w-full flex justify-end">
                <Tooltip
                    title="Clear all Notifications"
                    placement="bottom"
                    mouseEnterDelay={0.5}
                    arrow={{ pointAtCenter: true }}

                >
                    <Button
                        style={{
                            background: 'var(--primary_color)',
                            color: 'var(--white_A700)'
                        }}
                        size="middle"
                        shape="circle"
                        className="flex items-center justify-center"
                        onClick={() => handleUpdateView(notifications)}
                        disabled={notifications.length === 0}
                    >
                        <Eraser size={18} />
                    </Button>
                </Tooltip>
            </div>
            <List
                dataSource={notifications.filter((item) => item.viewed === false)}
                locale={{ emptyText: 'All clear, no notifications...' }}
                renderItem={item => (
                    <div key={item.uuid} className="relative flex flex-col my-3 border p-4 rounded-md shadow-md">
                        {item.viewed === false ?
                            <Button
                                className="absolute top-1 right-1 bg-red-500 text-white-A700"
                                size="small"
                                shape="circle"
                                onClick={() => {
                                    handleUpdateView([item]);
                                }}
                                icon={<X size={14} />}
                            />
                            : null}
                        <h2
                            className="text-base font-semibold"
                            style={{ color: 'var(--primary_color)' }}
                        >
                            {item.title}
                        </h2>
                        <div className="flex justify-between items-center mt-2 gap-5">
                            <p className='text-gray-600'>{item.description}</p>
                            <p className='text-gray-400 text-sm'>{getTimeDifference(item.createdAt)}</p>
                        </div>
                    </div>
                )}
                pagination={{
                    pageSize: 5,
                    hideOnSinglePage: true,
                    defaultCurrent: 1,
                    showSizeChanger: false,
                }}
            />
        </>
    );

    return (
        <div className="py-5 px-5 flex justify-end items-center gap-5 flex-wrap border-b border-[##EAEEF4]">
            <div className="flex items-center gap-5">
                <Popover
                    content={content}
                    trigger="click"
                    arrow={{ pointAtCenter: true }}
                    placement="bottomRight"
                >
                    <Badge count={newNotificationsLength || 0} className="cursor-pointer">
                        <Button shape="circle" size="large" icon={<BellOutlined />} />
                    </Badge>
                </Popover>

                <div className="flex items-center gap-2 cursor-pointer">
                    <Dropdown menu={{ items }} trigger={["click"]} placement="bottom" arrow>
                        <div className="flex items-center">
                            <Avatar size={50} src={user?.profileImageUrl || ""} />
                            <p style={{ margin: "0 10px" }}>{user?.name || ""}</p>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}